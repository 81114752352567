import React from 'react';

// Utils
import { UIDeveloperError, handleError } from '@anchorage/sentry';

// Styles
import styles from './index.css';

// Components
import Button, { ButtonTypes } from '../Button';
// Types
import { ClosedBy } from './Header';

export const submitButtonText = 'Submit';
export const cancelButtonText = 'Cancel';

type FooterWrapperProps = {
  children: React.ReactNode;
  [key: string]: any;
};

function FooterWrapper({
  children,
  ...restProps
}: FooterWrapperProps): JSX.Element {
  return (
    <div className={styles.footer} {...restProps}>
      {children}
    </div>
  );
}

interface BaseProps {
  cancelText?: string;
  onSubmit: () => any;
  submitText?: string;
  type?: ButtonTypes;
  testId?: string;
  [key: string]: any;
}

interface WithCancelProps extends BaseProps {
  onCancel: (closedBy?: ClosedBy) => any;
  cancelText?: string;
}

type Props = BaseProps | WithCancelProps;

function Footer({
  cancelText,
  onCancel,
  onSubmit,
  submitText = submitButtonText,
  type = 'primary',
  testId = '',
  ...restProps
}: Props): JSX.Element {
  if (cancelText && !onCancel) {
    // Developer error
    handleError(
      new UIDeveloperError(
        `Custom cancelText but no onCancel function provided to Modal component from common. Cancel button only renders when provided an onCancel function`,
      ),
    );
  }

  return (
    <FooterWrapper {...restProps}>
      {onCancel && (
        <Button
          data-testid={`cancel-${testId}`}
          type="tertiary"
          onClick={(): void => {
            onCancel('cancel');
          }}
        >
          {cancelText || cancelButtonText}
        </Button>
      )}
      {onSubmit && (
        <Button
          className={styles.submitButton}
          data-testid={`submit-${testId}`}
          type={type}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      )}
    </FooterWrapper>
  );
}

export default Footer;
