// Utils
import React from 'react';

import { UIDeveloperError, handleError } from '@anchorage/sentry';

// Styles
import styles from '../../index.css';

// Types
import { BannerActionsProps } from '../../_types';

// Components
import Button from '../../../Button';

const BannerActions: React.FC<BannerActionsProps> = ({ actions }) => {
  if (actions.length > 2) {
    handleError(new UIDeveloperError('Cannot pass more than 2 actions'));
  }

  const [primaryAction, secondaryAction] = actions;

  if (!secondaryAction) {
    return (
      <Button
        className={styles.actionButton}
        type="tertiary"
        {...primaryAction}
      >
        {primaryAction.text}
      </Button>
    );
  }

  return (
    <>
      <Button
        className={styles.actionButton}
        type="tertiary"
        {...secondaryAction}
      >
        {secondaryAction.text}
      </Button>
      <Button
        className={styles.actionButton}
        type="secondary"
        {...primaryAction}
      >
        {primaryAction.text}
      </Button>
    </>
  );
};

export default BannerActions;
