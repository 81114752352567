import React from 'react';

import { handleError } from '@anchorage/sentry';

export type FunctionType<T> = (value: T) => T;

interface ContextProps {
  getItem: <T>(key: string) => T | undefined;
  setItem: <T>(key: string, value: T) => void;
}

const contextInitialValue = {
  getItem: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setItem: <T,>(_key: string, _value: T | FunctionType<T>) => {},
};

const isServer = typeof window === 'undefined';

export const LocalStorageContext =
  React.createContext<ContextProps>(contextInitialValue);

const LocalStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const [storedValue, setValue] = React.useState(() => {
    try {
      return (!isServer && window.localStorage) ?? {};
    } catch (error) {
      handleError(error as Error);
      return {};
    }
  });

  const getItem = <T,>(key: string): T | undefined => {
    try {
      const item = storedValue[key as keyof typeof storedValue];
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      handleError(error as Error);
      return undefined;
    }
  };

  const setItem = <T,>(key: string, value: T | FunctionType<T>) => {
    try {
      const item = storedValue[key as keyof typeof storedValue];
      const parsedItem = item === undefined ? item : JSON.parse(item);
      const valueToStore =
        value instanceof Function ? value(parsedItem) : value;

      setValue((prevState) => ({
        ...prevState,
        [key]: JSON.stringify(valueToStore),
      }));
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      handleError(error as Error);
    }
  };

  return (
    <LocalStorageContext.Provider value={{ getItem, setItem }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export default LocalStorageProvider;
