import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { PropsWithChildren, forwardRef } from 'react';

import { textVariants } from './variants/text-variants';

type TextColors =
  | 'strong'
  | 'muted'
  | 'accent'
  | 'danger'
  | 'success'
  | 'inverted'
  | 'default'
  | 'info'
  | 'warning'
  | 'alert';

type TextProps = VariantProps<typeof textVariants> &
  PropsWithChildren & {
    /**
     * Whether the button is used as a child component within a Slot.
     * @default false
     */
    asChild?: boolean;
    /**
     * Custom class name to apply to the Text component.
     */
    className?: string;
    /**
     * The text color corresponding to Figma tokens.
     * ```
     * - `strong`   - Represents the token `color/ref/text/strong`       ` #FFFFFF`
     * - `muted`    - Represents the token `color/ref/text/muted`        ` #a2a4a9`
     * - `accent`   - Represents the token `color/ref/text/accent`       ` #03bfac`
     * - `inverted` - Represents the token `color/ref/text/inverted`     ` #000000`
     * - `default`  - Represents the token `color/ref/ui/status/default` ` #a2a4a9`
     * - `danger`   - Represents the token `color/ref/ui/status/danger`  ` #e8646f`
     * - `success`  - Represents the token `color/ref/ui/status/success` ` #79d297`
     * - `info`     - Represents the token `color/ref/ui/status/info`    ` #5580f6`
     * - `warning`  - Represents the token `color/ref/ui/status/warning` ` #ebb128`
     * - `alert`    - Represents the token `color/ref/ui/status/alert`   ` #ff7d52`
     * ```
     */
    color?: TextColors;
    'data-testid'?: string;
    title?: string;
  };

const colorMapping: { [key in TextColors]: string } = {
  strong: 'color-ref-text-strong',
  muted: 'color-ref-text-muted',
  accent: 'color-ref-text-accent',
  inverted: 'color-ref-text-inverted',
  default: 'color-ref-ui-status-default',
  danger: 'color-ref-ui-status-danger',
  success: 'color-ref-ui-status-success',
  info: 'color-ref-ui-status-info',
  warning: 'color-ref-ui-status-warning',
  alert: 'color-ref-ui-status-alert',
};

const Text = forwardRef<HTMLDivElement, TextProps>(function Text(
  {
    variant = 'bodyRegular',
    color = undefined,
    className = '',
    asChild = false,
    children = undefined,
    'data-testid': dataTestId = 'typography',
    title,
  },
  ref,
) {
  const Comp = asChild ? Slot : 'div';

  const textColor =
    color && colorMapping[color] ? `text-${colorMapping[color]}` : '';

  return (
    <Comp
      ref={ref}
      title={title}
      data-testid={dataTestId}
      className={cn([textColor, className, textVariants({ variant })])}
    >
      {children}
    </Comp>
  );
});

export { Text, type TextProps };
