// Utils
import cn from 'classnames';
import _omit from 'lodash/omit';
import React from 'react';

import { UIDeveloperError } from '@anchorage/sentry';

// Styles
import css from '../index.css';

// Types
import type { DescriptionProps } from '../_types';

// Components
import Text from '../../Text';
// Constants
import { HIGHLIGHTABLE_VARIANTS } from '../_constants';
// Hooks
import useStepContext from '../useStepContext';

const StepTrackerDescription: React.FC<DescriptionProps> = ({
  isHighlighted = false,
  className = '',
  ...restProps
}) => {
  const { variant: stepVariant } = useStepContext();

  if (isHighlighted && !HIGHLIGHTABLE_VARIANTS.includes(stepVariant)) {
    throw new UIDeveloperError(`Variant ${stepVariant} is not highlightable`);
  }

  const shouldApplyHighlight = React.useMemo(
    () => isHighlighted && HIGHLIGHTABLE_VARIANTS.includes(stepVariant),
    [isHighlighted, stepVariant],
  );

  const classes = cn({
    [className]: Boolean(className),
    [css[stepVariant]]: true,
    [css.description]: !shouldApplyHighlight,
  });

  return (
    <Text
      className={classes}
      size="small"
      {..._omit(restProps, ['variant', 'size'])}
    />
  );
};

StepTrackerDescription.displayName = 'StepTrackerDescription';

export default StepTrackerDescription;
