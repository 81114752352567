import { UIDeveloperError, handleError } from '@anchorage/sentry';

export default function formatCryptoCurrencyInputValue(value: string): string {
  if (!value) {
    return '';
  }

  if (parseFloat(value) < 0) {
    handleError(
      new UIDeveloperError(
        'Negative value passed to be formatted in formatCryptoCurrencyInputValue',
      ),
    );
  }

  const [val, dec] = value.split('.');
  const sanitizedValue = val.replace(/[a-zA-Z]+/g, '');
  // BigInt is used to be able to handle big values
  // If val is not existent (if value was .05 for example) then format as '0'
  const formattedValue = sanitizedValue
    ? BigInt(sanitizedValue).toLocaleString('en-US')
    : '0';
  const hasDecimal = dec !== undefined;

  return `${formattedValue}${hasDecimal ? `.${dec}` : ''}`;
}
