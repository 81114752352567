/* eslint-disable  import/no-unresolved */
import classnames from 'classnames';
// Utils
import * as CSS from 'csstype';
import _defaults from 'lodash/defaults';
import _get from 'lodash/get';
import React from 'react';

import { UIDeveloperError, handleError } from '@anchorage/sentry';

// Styles
import styles from './index.css';

import { TabSizes } from './Tab';

export type Props = {
  children: React.ReactNode;
  className?: string;
  align?: CSS.Property.TextAlign;
  width?: string | number;
  style?: React.CSSProperties;
  label: string;
  size?: TabSizes;
  [key: string]: any;
};

export default function Tabs({
  children,
  label,
  align = 'left',
  width,
  style = {},
  className = '',
  size = 'medium',
  ...restProps
}: Props): JSX.Element | null {
  const classes = classnames({
    [styles.tabs]: true,
    [className]: Boolean(className),
  });

  if (!label) {
    handleError(new UIDeveloperError("Property 'label' is missing in <Tabs>"));
    return null;
  }

  if (!children || !Array.isArray(children) || !children.length) {
    handleError(new UIDeveloperError("Children for <Tabs> can't be empty"));
    return null;
  }

  const activeIndex = children.findIndex((tab) =>
    _get(tab, 'props.selected', false),
  );
  const tabWidthPercent = 100 / children.length;

  // _defaults makes sure we don't override width and textAlign values
  // if they are passed with 'style' property
  const tabsStyles: React.CSSProperties = _defaults(style, {
    width,
    textAlign: align,
  });
  const barStyles: React.CSSProperties = {
    width: `${tabWidthPercent}%`,
    // Moves the indicator under the active bar by shifting its
    // x position. This code works b/c width sizes
    // are same for all <Tab>s in a <Tabs> group
    transform: `translate3d(${activeIndex * 100}%, 0px, 0px)`,
  };

  return (
    <nav
      className={classes}
      role="navigation"
      aria-label={label}
      style={tabsStyles}
      {...restProps}
    >
      {children}
      {size === 'medium' && (
        <>
          <div className={classnames([styles.bar, styles.barFull])} />
          <div className={styles.bar} style={barStyles} />
        </>
      )}
    </nav>
  );
}
